/* Schedule.css */
.schedule-container {
  padding: 2rem 0;
  background-color: #f8f9fa;
  border-radius: 1rem;
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.1);
}

.schedule-title {
  font-size: 2rem;
  color: #654321;
  margin-bottom: 1rem;
}

.schedule-text {
  font-size: 1.5rem;
  color: #333;
}

/* Add styles for the table */
table {
  font-size: 1.2rem;
}

th {
  background-color: #f8f9fa;
  font-weight: bold;
}

td, th {
  text-align: center;
  vertical-align: middle;
}