footer {
  background-color: #654321;
}

.social-media a {
  text-decoration: none;
}

.social-media i {
  font-size: 1.5em; /* larger icons */
}
