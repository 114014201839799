.contact-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
  flex: 1;
}

.contact-container h1 {
  text-align: center;
  color: #704238; /* adjust color to match your theme */
  margin-bottom: 20px;
}

.contact-container p {
  line-height: 1.6;
  color: #333; /* adjust color to match your theme */
  margin-bottom: 20px;
  font-size: larger;
}

.well {
  background-color: #f8f8f8;
  border: 5px solid #e3e3e3;
  border-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
