.AboutUsSec {
  text-align: center;
  padding: 20px;
}

.AboutUsSec h2 {
  font-size: 2em;
  content: center;
  margin-bottom: 10px;
}

.AboutUsSec p {
  font-size: 1.4em;
}
