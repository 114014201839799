.header-logo {
  max-width: 100%; /* Make the image responsive */
  max-height: 15vh; /* Set maximum height to 10% of the viewport height */
  height: auto;
}


.nav-link {
  color: #fff; /* change the color to white */
  transition: color 0.3s ease; /* add transition for smooth color change */
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.4em;
  font-weight: bold;
  text-align: center; /* Center the text */
  padding: 0.5em 1em; /* Add some padding to prevent overlap */
}

.navbar-collapse {
  justify-content: center; /* Center the links in the navbar */
}

.custom-navbar {
  background-color: #654321;
}

.navbar-toggler {
  padding: 0.10rem 0.25rem; /* Decrease the padding to reduce height */
}

.nav-link:hover {
  color: #ffd700; /* change color to gold on hover */
}

@media (max-width: 768px) {
  .nav-link {
    font-size: 0.8em; /* Reduce font size on smaller screens */
    padding: 0.05em .10em; /* Further reduce padding on smaller screens */
  }


}
