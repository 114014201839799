/* Hero.css */
.hero {
  position: relative;
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/two_aikido.png");
  display: flex; /* Add this line */
  flex-direction: column; /* Add this line */
  justify-content: flex-end; /* Add this line */
  align-items: center; /* Add this line to center the button horizontally */

}

.hero-content {
  position: relative;
  width: 100%; /* Add this line to ensure the content takes the full width */
  align-items: center; /* Add this line to center the button horizontally */


}

.hero-button-container {
  text-align: center; /* Center the button horizontally */
  margin-bottom: 20px; /* Add some space at the bottom */
}

.hero-button {
  font-size: 1.2em;
  padding: 15px 30px;
  border: none;
  transition: all 0.3s ease;
  width: auto; /* Change from 20vw to auto to adjust the width automatically */
  display: inline-block; /* Add this line to keep the button inline */
  cursor: pointer;
}

.hero-button:hover {
  background-color: #247383;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .hero h1,
  .hero p {
    font-size: 0.9em;
    padding: 5px;
  }

  .hero-button {
    font-size: 1em;
    padding: 10px 20px;
    width: 60vw;
  }

  .hero {
    background-image: url("../../assets/Sensei_Hero.jpeg");
  }
}
