.contact-modal .modal-dialog {
  max-width: 90vw;
  max-height: 90vh;
}

.contact-modal .modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.contact-modal .modal-body {
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  max-height: 70vh;
}

.contact-modal .modal-body textarea {
  height: 300px; /* Adjust this value to your liking */
  resize: vertical; /* This allows the user to resize the textarea vertically */
}
