.carousel {
  position: relative;
  width: 100%;
  max-width: 600px; /* adjust as needed */
  margin: 10px;
  border: 5px solid beige;
  border-radius: 5px;
  padding: 5px;
  background-color: beige;
}

.carousel-image {
  width: 100%;
  height: 400px; /* adjust as needed */
  object-fit: cover;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateY(-50%) scale(1.1);
}

.carousel-button:active {
  background-color: rgba(0, 0, 0, 0.9);
  transform: translateY(-50%) scale(0.9);
}

.carousel-prev {
  left: 10px;
}

.carousel-next {
  right: 10px;
}