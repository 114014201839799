/* FAQs.css */

.faqs-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .faqs-container h1 {
    text-align: center;
    color: #23647a; /* adjust color to match your theme */
    margin-bottom: 20px;
  }
  
  .faq {
    margin-bottom: 40px;
  }
  
  .faq h2 {
    color: #23647a; /* adjust color to match your theme */
    margin-bottom: 10px;
  }
  
  .faq p {
    line-height: 1.6;
    color: #333; /* adjust color to match your theme */
  }
  