/* NoticeSec.css */

.notice-container {
  background-color: #f8d7da; /* light red */
  color: #721c24; /* dark red */
  border: 1px solid #f5c6cb; /* darker red */
  text-align: center;
  width: 60vw;
}

.notice-text {
  font-weight: bold;
  font-size: 1.2em;
}

.highlight {
  color: #FF5733;
}

.time-placeholder {
  font-weight: bold;
  font-size: 1.2em; /* slightly larger than the main text */
  color: #FF5733;
  display: block; /* makes it a block-level element, adding some space around it */
  margin-top: 0.5em; /* adds some space above the time */
  margin-bottom: 0.5em; /* adds some space below the time */
}

.additional-info {
  font-size: 1.1em;
  margin-top: 1em; /* adds space above this paragraph */
  color: #333333; /* a slightly darker color for better readability */
}
