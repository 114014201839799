.what-is-aikido-sec {
  text-align: center;
  padding: 20px;
}

.what-is-aikido-sec h2 {
    font-size: 2em;
    margin-bottom: 10px;

}

.what-is-aikido-sec p {
  font-size: 1.4em;
  color: #333; /* dark text */
}
