/* Add or modify these classes in your CSS file */
.homepage-content {
  padding: 0; /* Remove padding */
}

/* Ensure that images are responsive */
.carousel-image {
  width: 100%;
  height: auto;
}

ul.left-aligned {
  text-align: left;
  justify-content: flex-start;

}