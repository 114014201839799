.classes-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .classes-page h1 {
    color: #23647a;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .class-level {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .class-level h2 {
    color: #23647a;
    margin-bottom: 10px;
  }
  
  .class-level p {
    line-height: 1.5;
    color: #333;
  }
  