.welcome-section {
    text-align: center;
    padding: 20px;
  }
  
  .welcome-section h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .welcome-section p {
    color: #333; /* dark text */
  }
  