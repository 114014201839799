/* AboutUs.css */

.aboutus-section {
  padding: 50px 0;
}

.aboutus-text {
  text-align: justify;
}

.aboutus-text h1 {
  text-align: center;
  color: #23647a; /* adjust color to match your theme */
  margin-bottom: 20px;
}

.aboutus-text p {
  line-height: 1.6;
  color: #333; /* adjust color to match your theme */
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .aboutus-text {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .aboutus-text {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .aboutus-text {
    font-size: 18px;
  }
}