body {
  margin: 0; /* Override user agent stylesheet */
  padding: 0;
  background-color: #3d2828;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Add this line */
}

.homepage-container,
.otherpages-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-image: url('../assets/vintage-crumpled-paper-textured-background.jpg');
  background-repeat: repeat;
  background-size: cover;
}

.homepage-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.homepage-content section {
  margin-right: 20px;
}

.homepage-image {
  max-width: 100%; /* Make this 100% */
  height: auto;
  display: block;
  margin: 0 auto;
}
